export const WhyAlvaro = (props) => {

  const { data } = props

  return (
    <section id="featured-services" className="featured-services">
      <div className="container footer-info">

        <div className="row gy-4">

          <div className="col-xl-3 col-md-6 d-flex" data-aos="zoom-out">
            <div className="service-item position-relative">
              <div className="icon"><i className="bi bi-activity icon"></i></div>
              <h4><span className="stretched-link">{data[0].title}</span></h4>
              <p>{data[0].desc}</p>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200">
            <div className="service-item position-relative">
              <div className="icon"><i className="bi bi-bounding-box-circles icon"></i></div>
              <h4><span href="" className="stretched-link">{data[1].title}</span></h4>
              <p>{data[1].desc}</p>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400">
            <div className="service-item position-relative">
              <div className="icon"><i className="bi bi-calendar4-week icon"></i></div>
              <h4><span href="" className="stretched-link">{data[2].title}</span></h4>
              <p>{data[2].desc}</p>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="600">
            <div className="service-item position-relative">
              <div className="icon"><i className="bi bi-broadcast icon"></i></div>
              <h4><a href="" className="stretched-link">{data[3].title}</a></h4>
              <p>{data[3].desc}</p>
            </div>
          </div>
          {/* {/* <!-- End Service Item --> */}

        </div>

      </div>
    </section>
    // <!-- End Featured Services Section -->

  )



}


