
// import {  useAuth } from '../AuthContext'

export const Navigation = (props) => {

  // const { user, logOut } = useAuth();

  //sticked
  return (
    <header id="header" className="header fixed-top" data-scrollto-offset="0">
      <div className="container d-flex align-items-center justify-content-between fixed">


        <a href="#" className="logo d-flex align-items-center scrollto me-auto me-lg-0">

          {/* <img src="img/logo.png" alt=""></img> */}
          <h1>Alvaro<span>.</span></h1>
        </a>
        {/* ms-auto mb-2 mb-lg-0 */}
        <nav id="navbar" className="navbar ">
          <ul>
            <li><a className="nav-link scrollto" href="#">Home</a></li>
            <li><a className="nav-link scrollto" href="#cta">About</a></li>


            <li><a className="nav-link scrollto" href="#services">Services</a></li>

            {/* <li><a className="nav-link scrollto" href="index.html#pricing">Pricing</a></li>
            <li><a className="nav-link scrollto" href="index.html#team">Team</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li><a className="nav-link scrollto" href="index.html#contact">Contact</a></li> */}

          </ul>
          <i className="bi bi-list mobile-nav-toggle d-none"></i>
        </nav>
        {/* {
          user ? <i onClick={logOut} className="bi bi-arrow-left-square"></i> : <a className="btn-getstarted scrollto" href="index.html#about">Get Started</a>
        } */}


      </div>
    </header>
  )
}
