import { WhyAlvaro } from "./WhyAlvaro"
import { Player } from '@lottiefiles/react-lottie-player';

import "./components.css"

export const WhyDataSecurity = (props) => {


  const { data } = props

  if (!data) {
    return "Loading..."
  }

  else {

    return (

      <section id="cta" className="cta">
        <div className="container" data-aos="zoom-out">

          <div className="row g-5">

            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
              <h3> <em>{data.start}</em> {data.rest}</h3>
              <p> {data.paragraph}</p>
              {/* <a className="cta-btn align-self-start" href="#">Call To Action</a> */}

              <ul>
                <li className="mb-3"><i className="bi bi-check-circle-fill"></i> <span className="ml-3">{data.whyAlvaro[0].desc}</span></li>
                <li className="mb-3"><i className="bi bi-check-circle-fill"></i> {data.whyAlvaro[1].desc}</li>
                <li className="mb-3"><i className="bi bi-check-circle-fill"></i> {data.whyAlvaro[2].desc}</li>
                <li className="mb-3"><i className="bi bi-check-circle-fill"></i> {data.whyAlvaro[3].desc}</li>


              </ul>
            </div>

            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              {/* <div className="img">
                <img src={data.image} alt="" className="img-fluid"></img>
              </div> */}
              <Player
                src='img/perimeter.json'
                className="player img-fluid animated1"
                loop
                autoplay
              ></Player>
            </div>

          </div>
          {/* <WhyAlvaro data={data}></WhyAlvaro> */}
        </div>


      </section>
    )

  }

}


// if (data) {
  // return (<section id="hero" classNameName="d-flex flex-column justify-content-center align-items-center" >
  //   <div classNameName="container text-center text-md-left" data-aos="fade-up">
  //     <h1>Welcome to <span>{props.data.title}</span></h1>
  //     <h2>{props.data.subtitle}</h2>
  //     <h2>{props.data.paragraph}</h2>
  //     <a href="#about" classNameName="btn-get-started scrollto">Get Started</a>
  //   </div></section >)

  // } else {

  //   <h2>Loading ....</h2>
  // }