import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

export const Testimonials = (props) => {

    const { data } = props

    return (
        <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">

                <div className="testimonials-slider swiper">
                    <div className="swiper-wrapper">

                        <Swiper
                            spaceBetween={50}
                            slidesPerView={3}
                            className="autoplay swiper-pagination"
                        >
                            {
                                data.map((item, index) => {

                                    return (
                                        < SwiperSlide key={index}>
                                            <div className="swiper-slide">
                                                <div className="testimonial-item">
                                                    <img src={item.img} className="testimonial-img" alt=""></img>
                                                    <h3>{item.name}</h3>
                                                    <h4>{item.company}</h4>
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                    </div>
                                                    <p>
                                                        <i className="bi bi-quote quote-icon-left"></i>
                                                        {item.text}
                                                        <i className="bi bi-quote quote-icon-right"></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )

                                })
                            }
                            {/* <!-- End testimonial item --> */}
                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>

            </div>
        </section >
    )
}