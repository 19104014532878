import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup, getAuth
} from "firebase/auth";

import { auth } from "./firebase";


// import { useNavigate } from "react-router-dom"

const userAuthContext = createContext();

export function AuthProvider({ children }) {



    // const navigate = useNavigate();

    const [user, setUser] = useState(null);

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }
    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
        return signOut(auth);
    }
    function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleAuthProvider);
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {

            setUser(currentuser);


            //setUser({})
        });

        return () => {
            unsubscribe();
        };
    }, [user]);

    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut, googleSignIn }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(userAuthContext);
}





