// import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { Home } from "./components/home"
import { Info } from "./components/info"


const ProtectedRoutes = () => {
    //const isAuth = useAuth();


    const { user } = useAuth();


    //return (user ? <Outlet /> : <Navigate to="/info" />);

    // return (user ? <Outlet /> : <Navigate to="/info" />);


    return (user ? <Home /> : <Info />);



};

export default ProtectedRoutes;  