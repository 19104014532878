// import "./hero.css"
import { useEffect } from "react";
import GLightbox from 'glightbox';

export const Hero = (props) => {

  const { data } = props


  useEffect(() => {

    const glightbox = GLightbox({
      selector: '.glightbox'
    });

  }, [])


  if (!data) {
    return "Loading..."
  }

  else {

    return (
      <section id="hero-animated" className="hero-animated d-flex align-items-center">
        <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
          {/* <img src="img/hero-carousel/hero-carousel-3.svg" className="img-fluid animated"></img> */}
          {/* <h1><span>Alvaro</span></h1> */}

          <h2><span>{data.title1}</span></h2>
          <h2><span>{data.title2}</span></h2>
          <p>{data.desc}</p>


          <div className="d-flex ">
            <a href="img/intro.mp4" className="glightbox btn-watch-video d-flex align-items-center" alt=""><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
          </div>



        </div>
      </section>

      // <section id="hero-fullscreen" className="hero-fullscreen d-flex align-items-center">
      //   <div className="container d-flex flex-column align-items-center position-relative" data-aos="zoom-out">
      //     <h2>Welcome to <span>Alvaro</span></h2>
      //     <p>{data.title1} {data.title2}  </p>
      //     <div className="d-flex">
      //       {/* <a href="#about" className="btn-get-started scrollto">Get Started</a> */}
      //       <a href="img/intro.mp4" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
      //     </div>
      //   </div>
      // </section>


    )

  }

}


// if (data) {
  // return (<section id="hero" classNameName="d-flex flex-column justify-content-center align-items-center" >
  //   <div classNameName="container text-center text-md-left" data-aos="fade-up">
  //     <h1>Welcome to <span>{props.data.title}</span></h1>
  //     <h2>{props.data.subtitle}</h2>
  //     <h2>{props.data.paragraph}</h2>
  //     <a href="#about" classNameName="btn-get-started scrollto">Get Started</a>
  //   </div></section >)

  // } else {

  //   <h2>Loading ....</h2>
  // }



//   <div className="row gy-5">
//   <div className="col-xl-6 col-md-6" data-aos="zoom-in" data-aos-delay="200" >
//     <h2><span>{data.title1}</span></h2>
//     <h2><span>{data.title2}</span></h2>
//     <p>{data.desc}</p>


//     <div className="d-flex ">
//       <a href="img/intro.mp4" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
//     </div>

//   </div>

//   <div className="col-xl-6 col-md-6" data-aos="zoom-in" data-aos-delay="200" >

//   </div>

// </div>
