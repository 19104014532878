import { Login } from './login'
import "./Info.css"

import JsonData from "../data/data-signedout.json";


export const Info = (props) => {

  const data = JsonData.Hero


  return (
    <section id="info" className="info d-flex align-items-center">
      <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
        {/* <img src="img/hero-carousel/hero-carousel-3.svg" className="img-fluid animated"></img> */}
        {/* <h2><span>{data.subtitle}</span></h2>
        <p>{data.desc}</p> */}
        <h1><span>{data.title}</span></h1>
        <h2><span>{data.title1}</span></h2>
        <h2><span>{data.title2}</span></h2>
        <p>{data.desc}</p>
        <div className='mt-4'>
          <Login></Login>
        </div>



      </div>
    </section>


  )



}


// if (data) {
  // return (<section id="hero" classNameName="d-flex flex-column justify-content-center align-items-center" >
  //   <div classNameName="container text-center text-md-left" data-aos="fade-up">
  //     <h1>Welcome to <span>{props.data.title}</span></h1>
  //     <h2>{props.data.subtitle}</h2>
  //     <h2>{props.data.paragraph}</h2>
  //     <a href="#about" classNameName="btn-get-started scrollto">Get Started</a>
  //   </div></section >)

  // } else {

  //   <h2>Loading ....</h2>
  // }