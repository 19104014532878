// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBXSpoXpZQnNvGegRvqIxS5Py5lmYYz4KA",
    authDomain: "alvaro-dp.firebaseapp.com",
    databaseURL: "https://alvaro-dp.firebaseio.com",
    projectId: "alvaro-dp",
    storageBucket: "alvaro-dp.appspot.com",
    messagingSenderId: "960538880008",
    appId: "1:960538880008:web:fea7de3019bbf195c0c9bd"
};


export const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://alvaro-security.com/finishSignUp',
    // This must be true.
    handleCodeInApp: true,
    iOS: {
        bundleId: 'com.alvaro-security.ios'
    },
    android: {
        packageName: 'com.alvaro-security.android',
        installApp: true,
        minimumVersion: '12'
    },
    // dynamicLinkDomain: 'alvaro-security.page.link'
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;