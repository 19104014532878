// import logo from './logo.svg';
// import JsonData from "./data/data.json";

// import { Home } from "./components/home";
import { Home } from "./components/home";
import { Info } from "./components/info";
import ConfirmEmail from "./components/confirm"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useEffect } from "react";
import { AuthProvider } from "./AuthContext"

// import GLightbox from 'glightbox';

import AOS from 'aos';
import 'aos/dist/aos.css';


// import { AuthProvider } from "./AuthContext";
import ProtectedRoutes from "./ProtectedRoutes";
function App() {


  useEffect(() => {



    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
  }, [])


  // return (
  //   <Home></Home>
  // );

  return (
    // <AuthProvider>

    //   <ProtectedRoutes></ProtectedRoutes>
    // </AuthProvider>


    <AuthProvider>
      <Router>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path='/' element={<Home />} />
            <Route path="*" element={<Home />} />

          </Route>



          <Route path="/info" element={<Info />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
        </Routes>
      </Router>
    </AuthProvider>
  )


}

export default App;
