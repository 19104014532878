import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Form, Alert } from "react-bootstrap";
// import { Button } from "react-bootstrap";
// import GoogleButton from "react-google-button";
import { useAuth } from "../AuthContext";


export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { logIn, googleSignIn } = useAuth();
    // const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await logIn(email, password);
            // navigate("/");
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            await googleSignIn();
            // navigate("/");
        } catch (error) {

        }
    };


    return (
        <div className="text-left">


            <form action="" method="post">

                <label >
                    Email:
                    <input type="email" name="email" className="mx-3"
                        onChange={(e) => setEmail(e.target.value)}></input>
                </label>




                <label>
                    Password:
                    <input type="password" name="password" className="mx-3"
                        onChange={(e) => setPassword(e.target.value)}></input>
                </label>



                <input type="submit" value="Login" className="mx-3" onClick={handleSubmit}></input>

            </form>
        </div>

    );
};

