export const Services = (props) => {

  const { data } = props

  if (!data) {
    return "Loading..."
  }

  else {

    return (
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">

          <div className="section-header">
            <h2>Use Cases</h2>
            {/* <p>Ea vitae aspernatur deserunt voluptatem impedit deserunt magnam occaecati dssumenda quas ut ad dolores adipisci aliquam.</p> */}


          </div>

          <div className="row gy-5">



            {
              data.map((item, index) => {

                return (
                  <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200" key={index}>
                    <div className="service-item">
                      <div className="img">
                        <img src={item.image} className="img-fluid" alt=""></img>
                      </div>
                      <div className="details position-relative1">
                        <div className="icon">
                          <i className={item.icon}></i>
                        </div>
                        <span href="#" className="stretched-link">
                          <h3>{item.name}</h3>
                        </span>
                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }

            {/* <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
              <div className="service-item">
                <div className="img">
                  <img src="img/services-1.jpg" className="img-fluid" alt=""></img>
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className="bi bi-activity"></i>
                  </div>
                  <span href="#" className="stretched-link">
                    <h3>Nesciunt Mete</h3>
                  </span>
                  <p>Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis.</p>
                </div>
              </div>
            </div> */}
            {/* <!-- End Service Item --> */}

            {/* <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
              <div className="service-item">
                <div className="img">
                  <img src="img/services-2.jpg" className="img-fluid" alt=""></img>
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className="bi bi-broadcast"></i>
                  </div>
                  <span href="#" className="stretched-link">
                    <h3>Eosle Commodi</h3>
                  </span>
                  <p>Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem.</p>
                </div>
              </div>
            </div> */}
            {/* <!-- End Service Item --> */}

            {/* <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
              <div className="service-item">
                <div className="img">
                  <img src="img/services-3.jpg" className="img-fluid" alt=""></img>
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className="bi bi-easel"></i>
                  </div>
                  <span href="#" className="stretched-link">
                    <h3>Ledo Markt</h3>
                  </span>
                  <p>Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti.</p>
                </div>
              </div>
            </div> */}
            {/* <!-- End Service Item --> */}

            {/* <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="500">
              <div className="service-item">
                <div className="img">
                  <img src="img/services-4.jpg" className="img-fluid" alt=""></img>
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className="bi bi-bounding-box-circles"></i>
                  </div>
                  <span href="#" className="stretched-link">
                    <h3>Asperiores Commodit</h3>
                  </span>
                  <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
                  <span href="#" className="stretched-link"></span>
                </div>
              </div>
            </div> */}
            {/* <!-- End Service Item --> */}

            {/* <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
              <div className="service-item">
                <div className="img">
                  <img src="img/services-5.jpg" className="img-fluid" alt=""></img>
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className="bi bi-calendar4-week"></i>
                  </div>
                  <span href="#" className="stretched-link">
                    <h3>Velit Doloremque</h3>
                  </span>
                  <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                  <span href="#" className="stretched-link"></span>
                </div>
              </div>
            </div> */}
            {/* <!-- End Service Item --> */}

            {/* <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="700">
              <div className="service-item">
                <div className="img">
                  <img src="img/services-6.jpg" className="img-fluid" alt=""></img>
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className="bi bi-chat-square-text"></i>
                  </div>
                  <span href="#" className="stretched-link">
                    <h3>Dolori Architecto</h3>
                  </span>
                  <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
                  <span href="#" className="stretched-link"></span>
                </div>
              </div>
            </div> */}
            {/* <!-- End Service Item --> */}

          </div>

        </div>
      </section>
    )

  }
}
