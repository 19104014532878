
import { Navigation } from "./navigation";
import { Hero } from "./Hero";
import { WhyDataSecurity } from "./WhyDataSecurity";
// import { WhatWeDo } from "./WhyAlvaro"
import { WhyAlvaro } from "./WhyAlvaro";
// import { About } from "./about";
import { Services } from "./services";
// import { Features } from "./features";
// import { Pricing } from "./pricing";
import { Footer } from "./footer";
import { Testimonials } from "./testimonials";

import JsonData from "../data/data.json";

export const Home = () => {

    return (
        <div id="home">
            <Navigation />
            <Hero data={JsonData.Hero}></Hero>
            <WhyAlvaro data={JsonData.valueProp}></WhyAlvaro>
            <WhyDataSecurity data={JsonData.DataSecurity}></WhyDataSecurity>
            <Testimonials data={JsonData.Testimonials}></Testimonials>
            {/* <Features data={JsonData.Features}></Features> */}
            <Services data={JsonData.Services}></Services>
            {/* <Pricing></Pricing> */}
            <Footer></Footer>
        </div>

    );
}



