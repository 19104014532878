import React, { useState } from "react";

import { useNavigate } from 'react-router-dom';

import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

// Confirm the link is a sign-in with email link.

import JsonData from "../data/data-signedout.json";

const ConfirmEmail = ({ url }) => {
    const [email, setEmail] = useState("");

    const [error, setError] = useState("");

    const navigate = useNavigate();

    const data = JsonData.Hero




    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {


            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    navigate("/")



                })
                .catch((error) => {
                });
        }
    };

    return (


        <section id="register" className="info d-flex align-items-center">
            <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">

                <h1><span>{data.title}</span></h1>
                <h2><span>{data.title1}</span></h2>
                <h2><span>{data.title2}</span></h2>
                {/* <h3>{data.desc}</h3> */}

                <h3 className='mt-4'> Please confirm your email to complete login </h3>
                <div className='mt-4'>




                    <div className="text-left">


                        <form action="" method="post">

                            <label >
                                Email:
                                <input type="email" name="email" className="mx-3" value={email}
                                    onChange={(e) => setEmail(e.target.value)}></input>
                            </label>



                            <input type="submit" value="Confirm" className="mx-3" onClick={handleSubmit}></input>



                        </form>
                    </div>





                </div>



            </div>
        </section>


    );
};


export default ConfirmEmail
